import React from "react"
import Page from "../components/page"
import ResponsiveImageGrid from "../components/responsive-image-grid"
import { themes } from "../styles/themes"
import paths from "../utils/paths"
import useScreenSize from "../utils/useScreenSize"

const architectureGrid = (labels, isSmallScreen) => [
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 63.5,
        src: "/images/architecture/villa-m-side-front.jpg",
        text: labels.architectureVillaMText,
        textContainerClass: "architecture-villa-m-side-front-text-container",
        textClass: "architecture-villa-m-side-front-text",
        alt: labels.architectureVillaMSideFrontAlt,
        imgTitle: labels.architectureVillaMImgTitle
      },
      {
        type: "image",
        weight: 35,
        src: "/images/architecture/villa-m-side-back.jpg",
        alt: labels.architectureVillaMSideBackAlt,
        imgTitle: labels.architectureVillaMImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/architecture/ae-invest-business-building-front-side.jpg",
        text: labels.architectureAEInvestText,
        textContainerClass: "architecture-ae-invest-text-container",
        textClass: "architecture-ae-invest-text",
        alt: labels.architectureAEInvestBusinessBuildingAlt,
        imgTitle: labels.architectureAEInvestImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    unified: true,
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/architecture/conceptual-solutions.jpg",
        alt: labels.architectureConceptualSolutionsAlt,
        imgTitle: labels.architectureConceptualSolutionsImgTitle,
        text: labels.architectureConceptualSolutionsText,
        textContainerClass: "architecture-conceptual-solutions-text-container",
        textClass: "architecture-conceptual-solutions-text"
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    unified: true,
    margin: true,
    gridClass: "architecture-nk-varteks-east-stand-grid-container",
    text: labels.architectureNKVarteksEastStandText,
    textContainerClass: "architecture-nk-varteks-east-stand-text-container",
    textClass: "architecture-nk-varteks-east-stand-text",
    textElement: (
      <div className="architecture-nk-varteks-east-stand-photos-text-container">
        <p className="architecture-nk-varteks-east-stand-photos-text">
          {labels.architectureNKVarteksEastStandPhotosText}
        </p>
      </div>
    ),
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/architecture/nk-varteks-stand-front.jpg",
        alt: labels.architectureNKVarteksEastStandFrontAlt,
        imgTitle: labels.architectureNKVarteksEastStandImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/architecture/nk-varteks-stand-back.jpg",
        alt: labels.architectureNKVarteksEastStandBackAlt,
        imgTitle: labels.architectureNKVarteksEastStandImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    unified: true,
    margin: true,
    gridClass: "architecture-arena-zagreb-lighting-design-grid-container",
    text: labels.architectureArenaZagrebLightingDesignText,
    textContainerClass:
      "architecture-arena-zagreb-lighting-design-text-container",
    textClass: "architecture-arena-zagreb-lighting-design-text",
    textElement: (
      <div className="architecture-arena-zagreb-lighting-design-photos-text-container">
        <p className="architecture-arena-zagreb-lighting-design-photos-text">
          {labels.architectureArenaZagrebLightingDesignPhotosText}
        </p>
      </div>
    ),
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/architecture/arena-zagreb-side.jpg",
        alt: labels.architectureArenaZagrebLightingDesignSideAlt,
        imgTitle: labels.architectureArenaZagrebLightingDesignImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/architecture/arena-zagreb-front.jpg",
        alt: labels.architectureArenaZagrebLightingDesignFrontAlt,
        imgTitle: labels.architectureArenaZagrebLightingDesignImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    unified: true,
    margin: true,
    gridClass: "architecture-infobip-lighting-design-grid-container",
    text: labels.architectureInfobipLightingDesignText,
    textContainerClass: "architecture-infobip-lighting-design-text-container",
    textClass: "architecture-infobip-lighting-design-text",
    grid: [
      {
        type: "image",
        weight: 32.33,
        src: "/images/architecture/infobip-office.jpg",
        alt: labels.architectureInfobipLightingDesignOfficeAlt,
        imgTitle: labels.architectureInfobipLightingDesignImgTitle
      },
      {
        type: "image",
        weight: 32.33,
        src: "/images/architecture/infobip-lobby.jpg",
        alt: labels.architectureInfobipLightingDesignLobbyAlt,
        imgTitle: labels.architectureInfobipLightingDesignImgTitle
      },
      {
        type: "image",
        weight: 32.33,
        src: "/images/architecture/infobip-floors.jpg",
        alt: labels.architectureInfobipLightingDesignFloorsAlt,
        imgTitle: labels.architectureInfobipLightingDesignImgTitle
      }
    ]
  }
]

export default function Architecture({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.architecture}
      description={labels.architectureDescription}
      keywords={labels.architectureKeywords}
      ogUrl={paths(labels).architecture}
      ogImage="/images/og-images/architecture.jpg"
      headerPadding
      footerPadding
    >
      <ResponsiveImageGrid
        grid={architectureGrid(labels, isSmallScreen)}
        rounded={true}
      />
      <div className="architecture-other-references-text-container">
        <p>{labels.architectureOtherReferencesText}</p>
        <br />
        <p>{labels.architectureZagorjeTehnobetonText}</p>
        <p>{labels.architectureLibertinaText}</p>
        <p>{labels.architectureZuzelText}</p>
        <p>{labels.architectureMedjimurjeVisokogradnjaText}</p>
        <p>{labels.architectureGumiimpexText}</p>
        <p>{labels.architectureOPGTukvicText}</p>
        <p>{labels.architectureIGMSljuncaraText}</p>
        <p>{labels.architectureSportskiParkText}</p>
        <p>{labels.architectureTIZZrinskiText}</p>
        <p>{labels.architectureChinchilaText}</p>
        <p>{labels.architectureSportskaDvoranaText}</p>
        <p>{labels.architectureGradskiBazeniText}</p>
      </div>
    </Page>
  )
}
